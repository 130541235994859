import React from 'react'
import { Fade } from "react-reveal";
import Project from '../../components/projects/Projects'
import './LivePreview.css'

const LivePreview = () => {
    const projects = [
        {
            name: "Afro Foods Hub",
            img: "./img/1.png",
            description: "Food delivery web application",
            link: "https://afro-foods-hub.onrender.com/",
        },
        {
            name: "Speedly",
            img: "./img/2.png",
            description: "Speedly revolutionizes cash management for micro and small businesses in retail and petrol stations",
            link: "https://getspeedly.com/"
        },
        {
            name: "Linkmeup Individual",
            img: "./img/7.png",
            description: "Identity and access management",
            link: "https://linkmeup.us/",
        },
        {
            name: "LinkMeUp Security",
            img: "./img/4.png",
            description: "Linkmeup Security website",
            link: "https://linkmeup.us/security",
        },
        {
            name: "Abamade eCommerce",
            img: "./img/5.png",
            description: "Abamade's website",
            link: "https://abamade.com.ng/",
        },
        {
            name: "Payhomes",
            img: "./img/6.png",
            description: "Payhomes web app",
            link: "https://payhomes.com.ng/",
        }
    ]

    return (
        <div className="projects-spot">
            <Fade left duration={1000}>
                <h3>Here's a sneak peek at a handful of my projects.</h3>
                <div className="projects">
                    { projects.map((project, index) => (
                    <div key={index}>
                        <Project project={project}/>
                    </div>
                    )) }
                </div>
            </Fade>
        </div>
    )
}

export default LivePreview
