import React from "react"
import './projects.css'

const Projects = ({ project }) => {
    return (
        <div className="main">
            <div className="project">
                <div className="img-holder">
                    <img src={require(`${project.img}`)} alt="preview"/>
                </div>
                <a className="link" href={project.link} target="_blank">
                    <p className="project-name"><b>{project.name}</b></p>
                    <p className="project-description">{project.description}</p>
                    <a href={project.link} target="_blank">{project.link}</a>
                </a>
            </div>
        </div>
    )
}

export default Projects
